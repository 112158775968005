"use client";

import Link from "next/link";
import React, { use, useEffect, useState } from "react";
import { X } from "lucide-react";
import Image from "next/image";
import { buttonVariants } from "./ui/button";
import { cn } from "@/lib/utils";
import PremiumQueenIcon from "./svg-icons/premium-queen-icon";

import FeatherIcon from "feather-icons-react";
import NavbarSearch from "./navbar-search";
import NavbarProfile from "./navbar-profile";
import { useSession } from "next-auth/react";
//import NavbarNotification from "./navbar-notification";
import axios from "axios";
import NavbarVideos from "./navbar-videos";
import dynamic from "next/dynamic";
import { revalidateGifts } from "@/lib/actions";
import NavbarPuzzle from "./navbar-puzzle";

const NavbarNotification = dynamic(
  () => import("@/components/navbar-notification"),
  {
    ssr: false,
  },
);

const Navbar = () => {
  const { data: session } = useSession();
  const [nav, setNav] = useState(false);
  const [availableGifts, setAvailableGifts] = useState([]);
  useEffect(() => {
    fetch(
      "/api/gift-premium/list-available-gifts",
      {
        // Adding method type
        method: "POST",

        // Adding headers to the request
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      },
      // "/api/gift-premium/list-available-gifts",
      // //       // { next: { tags: ["gifts"] },
      // { next: { revalidate: 300 } },
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => setAvailableGifts(data));
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const response = await fetch(
  //       "/api/gift-premium/list-available-gifts",
  //       // { next: { tags: ["gifts"] },
  //       { next: { revalidate: 300 } },
  //     );
  //     const data = await response.json();
  //     console.log(response);

  //     setAvailableGifts(data);
  //   };
  //   fetchData();
  // }, []);

  const navLinks = [
    { href: "/seri/egitimler", text: "Eğitimler" },

    { href: "/egitmenler", text: "Eğitmenler" },
    { href: "/seri/eglence", text: "Eğlence" },
    { href: "/bulmacalar", text: "Bulmacalar" },
    { href: "/haberler", text: "Haberler" },
    { href: "/urunler", text: "Ürünler" },
    {
      href: "/premium",
      text: "Premium Ol",
      className: "text-primary hover:text-primary-hover italic",
      hideWhenPremium: true,
    },
  ];

  return (
    <div className="nav absolute z-10 w-full bg-white shadow-md">
      <div className="container flex h-[60px] items-center justify-between lg:justify-start">
        <div className="flex h-full items-center border-r border-neutral-500">
          <h1 className="ml-2">
            <a
              className="xs:mr-0 ml-0 mr-2 inline-block"
              href="/"
              rel="noreferrer"
            >
              <Image
                src="/images/logo.png"
                width="126"
                height="26"
                className="hidden max-w-full xl:inline-block"
                alt="Satranç 365 Logo"
                priority={true}
              />
              <Image
                src="/images/logo-small.png"
                width="38"
                height="26"
                className="inline-block max-w-full xl:hidden"
                alt="Satranç 365 Logo"
                priority={true}
              />
              <span className="sr-only">Satranç 365</span>
            </a>
          </h1>
        </div>
        <div className="flex h-full flex-1 items-center justify-end lg:justify-between">
          <ul className="hidden h-full justify-center gap-0 divide-x divide-neutral-500 lg:flex">
            <li className="ml-0">
              <NavbarVideos />
            </li>

            {navLinks.map(
              (link) =>
                link.href !== "/seri/egitimler" &&
                link.href !== "/seri/eglence" &&
                (link.href !== "/bulmacalar" ? (
                  <li key={link.href} className="border-l-1 flex items-center">
                    <Link
                      href={link.href}
                      className={cn(
                        "mx-2 bg-white text-base text-black hover:text-neutral-600",
                        link.className,
                      )}
                      prefetch={true}
                    >
                      {link.text}
                    </Link>
                  </li>
                ) : (
                  <li key="3" className="ml-0">
                    <NavbarPuzzle />
                  </li>
                )),
            )}
          </ul>
          <ul className="flex h-full items-center">
            {/* Premium Queen */}
            {session?.user && session.user.isPremium == true && (
              <li className="flex h-full w-16 items-center pl-2">
                <Link
                  href={"/premium"}
                  className={cn(
                    buttonVariants({
                      variant: "text",
                      className:
                        "h-auto w-auto p-1 text-primary hover:text-primary-hover",
                      size: "icon",
                    }),
                  )}
                >
                  <PremiumQueenIcon width={38} height={38} />
                </Link>
              </li>
            )}
            {/* Login Butonu */}
            {!session?.user ? (
              <li className="hidden h-full items-center pl-2 lg:flex">
                <Link
                  href={"/login"}
                  className={cn(
                    buttonVariants({
                      variant: "outline",
                      size: "sm",
                      className:
                        "border-[3px] border-primary-focus py-1 text-center lg:mr-6",
                    }),
                    "bg-white text-sm text-black hover:bg-background",
                  )}
                >
                  Üye Girişi / <br className="hidden md:block" /> Üye Ol
                </Link>
              </li>
            ) : null}

            {session &&
            session.user &&
            session.user.isPremium === false &&
            availableGifts.length > 0 ? (
              <li className="flex h-full w-16 items-center border-l border-neutral-500">
                <NavbarNotification />
              </li>
            ) : null}
            {!session?.user && availableGifts.length > 0 ? (
              <li className="flex h-full w-16 items-center border-l border-neutral-500">
                <NavbarNotification />
              </li>
            ) : null}
            {session && session.user ? (
              <li className="flex h-full w-16 items-center border-l border-neutral-500">
                <NavbarProfile />
              </li>
            ) : null}
            {/* Search */}
            <li className="flex h-full w-16 items-center border-l border-r border-neutral-500 lg:border-r-0">
              <NavbarSearch />
            </li>
          </ul>
        </div>
        <div
          onClick={() => setNav(!nav)}
          className="z-10 flex h-full w-16 cursor-pointer items-center justify-center text-gray-500 lg:hidden"
        >
          {nav ? (
            <X size={50} />
          ) : (
            <FeatherIcon icon="align-justify" size={30} />
          )}
        </div>

        {nav && (
          <ul className="absolute  left-0 top-0 flex h-screen w-full flex-col items-center justify-center bg-gradient-to-b from-black to-gray-800 pt-[70px] text-gray-500">
            <li className="m-0 cursor-pointer px-4 py-2 text-4xl capitalize ">
              <Link onClick={() => setNav(!nav)} href={"/login"}>
                Üye Girişi / Üye Ol
              </Link>
            </li>
            {navLinks.map((link) => (
              <li
                key={link.href}
                className="cursor-pointer px-4 py-2 text-4xl capitalize"
              >
                <Link
                  href={link.href}
                  onClick={() => setNav(!nav)}
                  className={cn(link.className)}
                >
                  {link.text}
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
export default Navbar;
