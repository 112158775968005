import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { User } from "@phosphor-icons/react/dist/ssr";
import Link from "next/link";
import { cn } from "@/lib/utils";
import { signOut } from "next-auth/react";
import { Button } from "./ui/button";
import { useSession } from "next-auth/react";
import { useEffect, useRef, useState } from "react";

const NavbarPuzzle = () => {
  const { data: session } = useSession();
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  function handleOnClick() {
    setAlertOpen(!alertOpen);
  }

  // function toggleAlert() {
  //   setAlertOpen(!alertOpen);
  // }

  const handleMouseEnter = () => {
    setAlertOpen(true);
  };

  const handleMouseLeave = () => {
    setAlertOpen(false);
  };

  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setAlertOpen(false);
    }
  };
  const handleClickOutside = (event: Event) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target as Node)
    ) {
      setAlertOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return (
    <Popover open={alertOpen}>
      <PopoverTrigger
        //  onClickCapture={toggleAlert}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={cn(
          "navbar-popover flex h-full items-center justify-center bg-white px-2 text-black hover:bg-primary hover:text-white",
        )}
      >
        Bulmacalar
        <span className="sr-only">Bulmacalar</span>
      </PopoverTrigger>
      <PopoverContent
        className="rounded-none rounded-b-md border-none bg-primary p-0 py-3 lg:w-[170px]"
        sideOffset={0}
        align="end"
        ref={wrapperRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <ul className="w-full">
          <li className="w-full">
            <Link
              onClickCapture={handleOnClick}
              href="/bulmacalar?gm=puzzles&pt=pozisyonel"
              className="block w-full px-4 py-1 text-right text-white hover:bg-[#372980]"
            >
              Pozisyonel
            </Link>
          </li>
          <li className="w-full">
            <Link
              onClickCapture={handleOnClick}
              href="/bulmacalar?gm=puzzles&pt=taktiksel"
              className="block w-full px-4 py-1 text-right text-white hover:bg-[#372980]  "
            >
              Taktik
            </Link>
          </li>

          <li className="w-full">
            <Link
              onClickCapture={handleOnClick}
              href="/bulmacalar?gm=puzzleRush"
              className="block w-full px-4 py-1 text-right text-white hover:bg-[#372980]"
            >
              Bulmaca Yarışı
            </Link>
          </li>
        </ul>
      </PopoverContent>
    </Popover>
  );
};

export default NavbarPuzzle;
